import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BookIcon from '@mui/icons-material/Book';
import MenuIcon from '@mui/icons-material/Menu';

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key="我的博客" component="a" href="/post-test">
            <ListItemIcon><BookIcon /></ListItemIcon>
            <ListItemText primary="我的博客" />
        </ListItem>
        <ListItem button key="最近的消息" component="a" href="/contact">
            <ListItemIcon><RestoreIcon /></ListItemIcon>
            <ListItemText primary="最近的消息" />
        </ListItem>
        <ListItem button key="我的资源(未完成" component="a" href="/">
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary="我的资源(未完成" />
        </ListItem>
        <ListItem button key="我的朋友们" component="a" href="/friends">
            <ListItemIcon><FavoriteIcon /></ListItemIcon>
            <ListItemText primary="我的朋友们" />
        </ListItem>
        <ListItem button key="关于我" component="a" href="/about">
            <ListItemIcon><AutoAwesomeIcon /></ListItemIcon>
            <ListItemText primary="关于我" />
        </ListItem>
      </List>
      <Divider />
      <h1>QwQ</h1>
    </Box>
  );

  return (
    <div>
        <React.Fragment key={"top"}>
          <Button onClick={toggleDrawer("top", true)}><MenuIcon /></Button>
          <Drawer
            anchor="top"
            open={state["top"]}
            onClose={toggleDrawer("top", false)}
          >
            {list("top")}
          </Drawer>
        </React.Fragment>
    </div>
  );
}