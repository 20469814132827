import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BookIcon from '@mui/icons-material/Book';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { Container } from '@mui/material'
import Typography from '@mui/material/Typography';
import TemporaryDrawer from '../components/frontdrawer';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function InfoBar() {
  const [open, setOpen] = React.useState({open: true, vertical: 'top', horizontal: 'left'});

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} sx={{ top: -70}}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          网站建设中.......
        </Alert>
      </Snackbar>
    </Stack>
  );
}



const fullpageOptions = {
  anchors: ['Hello', 'World'],
  sectionsColor: ['#00c4ff', '#b6eeff'],
  callbacks: ['onLeave', 'afterLoad'],
}




const FullpageWrapper = fullpageProps => (
  <ReactFullpage
    {...fullpageOptions}
    render={({ state, fullpageApi }) => {
      console.log('render prop change', state)

      if (state.callback === 'onLeave') {
        if (state.direction === 'down') {
          console.log('going down...' + state.origin.index)
        }
      }

      return (
        <div id="fullpage-wrapper">
          <div className="section section1">
            <InfoBar 
              style={{
                  top: '0px',
                }}/>
            <Box className='topnav' >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}
              >
                <Tooltip TransitionComponent={Zoom} title="看看我的文章" arrow>
                <a href='https://blog.codynet.work'><Button startIcon={<BookIcon />}       >博客</Button></a>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="看看我最近发的牢骚" arrow>
                <Button startIcon={<RestoreIcon />}    >最近</Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="我的网盘(建设中" arrow>
                <Button startIcon={<FolderIcon />}     >资源</Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="我的好朋友们" arrow>
                <a href='/friends'><Button startIcon={<FavoriteIcon /> }   >友链</Button></a>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="关于我" arrow>
                <Button startIcon={<AutoAwesomeIcon />}>关于</Button>
                </Tooltip>
              </Stack>
            </Box>
            <Box className="topdrawer">
              <TemporaryDrawer />
            </Box>
            
            <Container>
              <Typography variant="h1" component="h2">
                  Hello world
              </Typography>
            </Container>
            <Box 
              onClick={() => fullpageApi.moveSectionDown()}
              className="moveDownBox"
            >
              <KeyboardArrowDownIcon 
                sx={{
                  position: 'absolute',
                  width: 100, 
                  height: 100, 
                  bottom: '-20px' ,
                  color: '#FFFFFF',
                }} />
            </Box>
          </div>
          <div className="section">
            <h3>此处还在建设中</h3>
          </div>
        </div>
      )
    }}
  />
)

export default FullpageWrapper;
